// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useMediaQuery } from 'react-responsive'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import MediaState from '../media-state'

import MessageBus from '../message-bus'
import '../message-bus/style.less'

import Header from '../header'
import '../header/style.less'

import Footer from '../footer'
import '../footer/style.less'

import InitalMetadata from '../schema/inital-metadata'
import UpdateTitle from '../schema/update-title'
import OrganisationSchema from '../schema/organisation-schema'
import WebsiteSchema from '../schema/website-schema'

import { updateMediaState, updateScreenSizeState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Styles
import '../../styles/index.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      client: false,
    }
  }

  /** after mount */
  componentDidMount() {
    this.setState({ client: true })
  }

  /** on mount */
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      if (this.state.client === true) {
        const element = document.getElementById('layout')
        element.scrollTop = 0
      }
    }
  }

  /** standard renderer */
  render() {
    const { client } = this.state
    const {
      children,
      uri = '',
      mediaState,
      screenSizeState,
      updateMediaState,
      updateScreenSizeState,
      className = 'standard-page',
      websiteState,
      pageContext,
    } = this.props
    const { typeface, mode, nav } = websiteState
    const { currentWidth, currentHeight } = mediaState

    return (
      <Fragment>
        <MediaState
          className={`layout container ${className} ${typeface} ${mode}-mode ${nav}-nav`}
          id="layout"
          data-uri={uri}
          useMediaQuery={useMediaQuery}
          updateMediaState={updateMediaState}
          screenSizeState={screenSizeState}
          updateScreenSizeState={updateScreenSizeState}
          client={client}
        >
          <InitalMetadata />
          <UpdateTitle />
          <OrganisationSchema />
          <WebsiteSchema />
          <Header
            currentWidth={currentWidth}
            currentHeight={currentHeight}
            nav={nav}
            uri={uri}
            lang={pageContext.intl.language}
            originalPath={pageContext.intl.originalPath}
          />
          <div className="rest">
            <main>{children}</main>
            <Footer uri={uri} lang={pageContext.intl.language} />
          </div>
        </MediaState>
        <MessageBus />
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
    screenSizeState: state.screenSizeState,
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateMediaState(payload) {
      dispatch(updateMediaState(payload))
    },
    updateScreenSizeState(payload) {
      dispatch(updateScreenSizeState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedLayout = compose(
  withState // Add state
)(Layout)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedLayout
